import logo from './logo.svg';
import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import CommingSoon from "./ComingSoon/Comingsoon"

const App=()=>{
return(
  <div className='App'>
  <BrowserRouter>
    <Routes>
    <Route exact path="/" element={<CommingSoon/>} /> 
    </Routes>
  </BrowserRouter>
  </div>
)
}

export default App;
