import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./coming.css"
import logo from "../assets/smlogo-full.svg"
import market from "../assets/smarket1.png"
const Comingsoon = () => {
return (

<>
<Row  className="main_container">
  <Col  md={4}  className="comingsooncol1">
    <div className="img_cont">
      <p >
      <img src={logo} alt="" width="150rem" height="150rem" />
      </p>
      <h1 className='logos'>smaarket</h1>
      </div>
  </Col>
  <Col md={8} className="comingsooncol2">
    <div className="coming_cont1">
      <h4 className='coming_h4'>smaarket is coming !</h4>
    <p className="coming_p1">Our platform is under construction.
      <br/> But we are ready to go..!!
    </p>
    <p className="coming_p1"> </p>
    <p className="coming_p2">
    There is no better place to start your search for exciting and innovative startups and products that will astound and surprise you with live funding campaigns and a global marketplace for technology transfer, licensing, research opportunities, and commercialization.

    </p>
    <p className="coming_p2">Be the first to see what's next !!!</p>
    <p className="coming_p2">Contact us for your queries, we're more than happy to respond</p>
    <p className="coming_p2" style={{color:"black",fontWeight:"600"}}>anand@smaarket.com</p>
    </div>

  
  </Col>
</Row>
</>

)
}

export default Comingsoon