// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n*,\r\n*::after,\r\n*::before {\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tbox-sizing: inherit;\r\n}\r\nhtml { \r\n\r\n  scroll-behavior: smooth;\r\n}   \r\n\r\nbody {\r\n  font-family: 'Montserrat', sans-serif;\r\n  position: relative;\r\n  background-color: #fff;\r\n  }\r\n\r\n.logos{\r\n  color: #ffffff;\r\n  font-weight: 700;\r\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AAGA;;;CAGC,SAAS;CACT,UAAU;CACV,mBAAmB;AACpB;AACA;;EAEE,uBAAuB;AACzB;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;EAClB,sBAAsB;EACtB;;AAEF;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":["\r\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700;800;900&display=swap');\r\n\r\n*,\r\n*::after,\r\n*::before {\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tbox-sizing: inherit;\r\n}\r\nhtml { \r\n\r\n  scroll-behavior: smooth;\r\n}   \r\n\r\nbody {\r\n  font-family: 'Montserrat', sans-serif;\r\n  position: relative;\r\n  background-color: #fff;\r\n  }\r\n\r\n.logos{\r\n  color: #ffffff;\r\n  font-weight: 700;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
